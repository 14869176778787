import colors from "../colors";

export default {
  styles: function(data) {
    return {
      control: (provided, state) => {
        const isOpen = state.selectProps.menuIsOpen && {
          boxShadow: `inset 0 0 0 2px ${colors.red54}`,
          "& .ico-arrow:before, & .ico-error_status": {
            color: colors.pink
          }
        };
        const large = data.size === "large" && {
          boxShadow: "none",
          backgroundColor: "transparant"
        };
        const small = data.size === "small" && {
          height: 36,
          "& .ico-arrow": {
            width: 18,
            height: 18,
            minWidth: 18,
            top: state.selectProps.menuIsOpen ? 2 : -2,
            "&:before": {
              fontSize: 18,
              width: 18,
              height: 18
            }
          }
        };
        const isClearable = (state.selectProps.isClearable || data.active) &&
          state.hasValue && {
            boxShadow: `inset 0 0 0 2px ${colors.red54}`,
            "& .ico-error_status:before": {
              color: colors.pink
            }
          };

        return {
          ...provided,
          height: 54,
          width: "100%",
          border: "none",
          minHeight: "auto",
          boxShadow: `inset 0 0 0 1px ${colors.black9}`,
          cursor: "pointer",
          backgroundColor: data.noDisabled && "transparant",
          flexWrap: "nowrap",
          ...small,
          ...isClearable,
          ...isOpen,
          ...large,
          "& [class*='ico-']": {
            color: colors.black54
          },
          "& .ico-error_status": {
            display: "inline-block",
            width: 18,
            cursor: "pointer",
            height: 18,
            "&:before": {
              fontSize: 18
            }
          }
        };
      },
      group: () => ({
        paddingBottom: 9,
        "& .group_flex": {
          display: "flex",
          margin: "0 9px 3px",
          alignItems: "center",
          boxShadow: `0 1px 0 0 ${colors.black9}`,
          paddingBottom: 3,
          "& + div": {
            padding: 9
          },
          "& svg": {
            width: 18,
            height: 18,
            "& + *": {
              paddingLeft: 9,
              fontSize: 10,
              letterSpacing: 1,
              margin: 0
            }
          }
        }
      }),
      indicatorsContainer: provided => {
        const small = data.size === "small" && {
          padding: "0 0 0 4px"
        };
        return {
          ...provided,
          ...small,
          "& button": {
            padding: data.size === "small" ? 0 : 9,
            "& span": {
              margin: 0
            }
          }
        };
      },
      indicatorSeparator: () => ({
        display: "none"
      }),
      menu: provided => ({
        ...provided,
        marginTop: 0,
        zIndex: 1000,
        boxShadow: `0 2px 5px 1px ${colors.black27}`,
        left: 3,
        width: "calc(100% - 6px)",
        overflow: "hidden"
      }),
      menuList: provided => ({
        ...provided,
        maxHeight: data.menuHeight || 126,
        padding: "9px 10px 9px 9px",
        "&::-webkit-scrollbar": {
          width: 8,
          background: "none"
        },
        "&::-webkit-scrollbar-track": {
          margin: 9,
          right: 3,
          background: "none"
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset -4px 0 0 0 #fff",
          background:
            "linear-gradient(to right, rgba(0, 0, 0, 0.18) 0%, rgba(0, 0, 0, 0.18) 50%, rgba(5, 5, 5, 0) 51%, rgba(229, 229, 229, 0) 100%)",
          borderRadius: 2
        }
      }),
      option: provided => ({
        ...provided,
        color: colors.black90,
        fontSize: 14,
        lineHeight: "36px",
        backgroundColor: "transparant",
        padding: "0 9px",
        cursor: "pointer",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        display: "flex",
        justifyContent: "space-between",
        "& span": {
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis"
        },
        "&:hover": {
          color: colors.pink,
          borderRadius: 4,
          backgroundColor: colors.red9
        }
      }),
      placeholder: (provided, state) => {
        const styles = data.label &&
          (state.hasValue || state.selectProps.menuIsOpen) && {
            color:
              data.active || state.selectProps.menuIsOpen || (state.hasValue && data.isClearable)
                ? colors.pink
                : colors.black36,
            transform:
              data.size === "small"
                ? "translate(-8px,-27px) scale(0.75)"
                : "translate(-15px, -37px) scale(0.75)",
            transition: "top 0.1s",
            backgroundColor: colors.white,
            padding: "0 4px"
          };
        const hide = (state.hasValue || (state.selectProps.menuIsOpen && data.isSearchable)) &&
          !data.label && {
            display: "none"
          };
        const small = data.size === "small" && {
          fontSize: 14,
          fontWeight: "normal",
          fontStyle: "normal",
          lineHeight: "18px",
          letterSpacing: 0,
          color:
            data.active || state.selectProps.menuIsOpen || state.hasValue
              ? colors.pink
              : colors.black36
        };

        return {
          ...provided,
          fontSize: 16,
          fontWeight: 500,
          color: colors.black36,
          ...small,
          ...styles,
          ...hide
        };
      },
      singleValue: (provided, state) => {
        const small = data.size === "small" && {
          fontSize: 14,
          fontWeight: "normal",
          lineHeight: "18px",
          color: state.selectProps.isClearable && colors.pink
        };
        const large = data.size === "large" && {
          fontSize: 25,
          letterSpacing: -0.2,
          color: colors.pink,
          fontWeight: "normal"
        };
        return {
          ...provided,
          fontSize: 16,
          fontWeight: 500,
          color: data.active || state.isFocused ? colors.pink : colors.black54,
          maxWidth: "calc(100% - 10px)",
          ...small,
          ...large,
          ".websaSelect:hover &": {
            color: colors.pink
          },
          "[class*='ico-'] ~ &": {
            left: 40
          }
        };
      },
      valueContainer: (provided, state) => {
        const svg = state.hasValue && {
          "& svg": {
            width: 24,
            height: 24,
            marginLeft: 2,
            marginBottom: 5,
            "& + div": {
              left: 58,
              top: 14
            }
          }
        };
        const small = data.size === "small" && {
          // maxWidth: "calc(100% - 56px)",
          paddingRight: 0
          // overflow: "hidden"
        };
        return {
          ...provided,
          overflow: "visible",
          paddingLeft: data.size === "small" ? 7 : 15,
          ...svg,
          ...small,
          "& > [class*='ico-']": {
            display: "inline-block",
            left: -7,
            top: 1,
            "&:before": {
              color: colors.pink
            }
          }
        };
      },
      container: provided => ({
        ...provided,
        minWidth: data.minWidth || data.width,
        width: data.width || 365,
        maxWidth: data.maxWidth || data.width,
        marginBottom: data.marginBottom || 27
      }),
      input: provided => {
        const small = data.size === "small" && {
          fontSize: 14,
          fontWeight: "normal",
          lineHeight: "18px",
          marginRight: 0
        };
        return {
          ...provided,
          color: colors.pink,
          ...small
        };
      }
    }
  }
}
