import colors from "../colors";

export default {
  host: 'advancedhosting.com',
  styles: {
    footer: {
      fontSize: 14,
      fontWeight: 300,
      lineHeight: "18px",
      letterSpacing: 0,
      color: colors.black45
    }
  }
};
