export default {
  black2: "rgba(0, 0, 0, 0.02)",
  black5: "rgba(0, 0, 0, 0.05)",
  black9: "rgba(0, 0, 0, 0.09)",
  black18: "rgba(0, 0, 0, 0.18)",
  black27: "rgba(0, 0, 0, 0.27)",
  black36: "rgba(0, 0, 0, 0.36)",
  black45: "rgba(0, 0, 0, 0.45)",
  black54: "rgba(0, 0, 0, 0.54)",
  black90: "rgba(0, 0, 0, 0.9)",
  red9: "rgba(249, 19, 115, 0.09)",
  red54: "rgba(249, 19, 115, 0.54)",
  red90: "rgba(249, 19, 115, 0.9)",
  blue5: "rgba(32, 143, 245, 0.05)",
  blue9: "rgba(32, 143, 245, 0.09)",
  blue12: "rgba(32, 143, 245, 0.12)",
  blue18: "rgba(32, 143, 245, 0.18)",
  blue27: "rgba(32, 143, 245, 0.27)",
  blue36: "rgba(32, 143, 245, 0.36)",
  blue54: "rgba(32, 143, 245, 0.54)",
  blue72: "rgba(32, 143, 245, 0.72)",
  blue81: "rgba(32, 143, 245, 0.81)",
  blue90: "rgba(32, 143, 245, 0.9)",
  green9: "rgba(0, 146, 62, 0.09)",
  green72: "rgba(0, 146, 62, 0.72)",
  green90: "rgba(0, 146, 62, 0.9)",
  nightBlue: "#061b2e",
  orange9: "rgba(255, 96, 0, 0.09)",
  orange72: "rgba(255, 96, 0, 0.72)",
  orange90: "rgba(255, 96, 0, 0.9)",
  white: "#ffffff",
  white72: "rgba(255, 255, 255, 0.72)",
  yellow9: "rgba(255, 182, 0, 0.09)",
  yellow54: "rgba(255, 182, 0, 0.54)",
  yellow72: "rgba(255, 182, 0, 0.72)",
  yellow90: "rgba(255, 182, 0, 0.9)",
  pink: "rgb(249, 19, 115)",
  pink9: "rgba(249, 19, 115, 0.09)",
  pink18: "rgba(249, 19, 115, 0.18)",
  grad1: "rgb(244, 164, 84)",
  grad2: "rgb(244, 31, 138)"
};
