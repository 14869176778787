import React from "react";

import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

import WebsaThemeProvider from "../ThemeProvider"

class WebsaUITheme extends React.Component {
  render () {
    return (
      <StyledEngineProvider>
        <ThemeProvider theme={WebsaThemeProvider(this.props.theme)}>
          {this.props.children}
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default WebsaUITheme
