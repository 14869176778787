import { get } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import Select, { createFilter } from "react-select";

import ClearIndicator from "./ClearIndicator";
import DropdownIndicator from "./DropdownIndicator";
import GroupHeading from "./GroupHeading";
import Input from "./Input";
import Option from "./Option";
import ValueContainer from "./ValueContainer";

import { useTheme } from "@mui/material";

/**
 * @function WebsaSelect
 *
 * @returns {jsx} React Conponent
 *
 * @param props
 */
export default function WebsaSelect(props) {
  const { options, placeholder, isSearchable, isClearable, value, onChange, isNumeric, name } = props;

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: "start",
  };

  const theme = useTheme();

  function handleChange(selectedOption) {
    const type = get(selectedOption, "value", "");
    if (onChange) onChange(type);
  }
  return (
    <Select
      styles={theme.customComponents.websaSelect.styles(props)}
      className="websaSelect"
      name={name}
      options={options}
      placeholder={placeholder}
      value={value}
      components={{
        DropdownIndicator,
        Option,
        ValueContainer,
        ClearIndicator,
        GroupHeading,
        Input: Input.bind(this, isNumeric)
      }}
      // defaultMenuIsOpen
      isClearable={isClearable || false}
      isSearchable={isSearchable || false}
      isDisabled={options && options.length === 1 && value && !isClearable}
      defaultOptions
      defaultValue={value}
      onChange={handleChange}
      filterOption={createFilter(filterConfig)}
    />
  );
}

WebsaSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.string),
    PropTypes.objectOf(PropTypes.number)
  ]),
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  isNumeric: PropTypes.bool
};

WebsaSelect.defaultProps = {
  placeholder: "",
  value: null,
  isClearable: false,
  isSearchable: false,
  isNumeric: false
};
