import PropTypes from "prop-types";
import React from "react";
import { components } from "react-select";

import svg from "./svg-icons.svg";

const { Placeholder } = components;
const ValueContainer = ({ children, ...props }) => {
  const {
    isFocused,
    selectProps: { placeholder, value }
  } = props;
  return (
    <components.ValueContainer {...props}>
      <Placeholder {...props} isFocused={isFocused}>
        {placeholder}
      </Placeholder>
      {!!value && !!value.icon && (
        <svg viewBox="0 0 24 24">
          <use xlinkHref={`${svg}#${value.icon.toLowerCase()}`} />
        </svg>
      )}
      {!!value && !!value.iconFont && <span className={value.iconFont} />}
      {React.Children.map(children, child => {
        return child && child.type !== Placeholder ? child : null;
      })}
    </components.ValueContainer>
  );
};

ValueContainer.propTypes = {
  isFocused: PropTypes.bool,
  selectProps: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.arrayOf(PropTypes.object).isRequired
};

ValueContainer.defaultProps = {
  selectProps: {},
  isFocused: false
};

export default ValueContainer;
