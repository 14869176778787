import React from "react";

const GloryToUkraine = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 0L24 24V0H0Z" fill="#FFD500"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.6667 0L24.0007 13.334V0H10.6667Z" fill="#005BBB"/>
  </svg>
);

export default GloryToUkraine
