import { tss } from 'tss-react';
import React, {useState} from 'react';
import styles from "./styles";
import Link from "@mui/material/Link";
import SliderForm from "../Shared/Slider/SliderForm";
import WebsaUITheme from '../Shared/WebsaUITheme';
import ThemeProvider from '../Shared/ThemeProvider';

import RailsForm from '../Shared/RailsForm';
import RailsRoutes from '../Shared/RailsRoutes';

import OutlinedInput from "../Shared/OutlinedInput"
import Button from "@mui/material/Button"
import DividerSocialBtn from "../Shared/DividerSocialBtn/DividerSocialBtn";
import CheckboxLabel from "../Shared/CheckboxLabel/CheckboxLabel";
import Copyright from "../Shared/Copyright/Copyright";

export default function SignUp(props) {
  const theme = ThemeProvider(props.theme);
  const { classes } = tss.create(styles(theme))();
  const [params, setParams] = useState(props.user || {});
  const [errors, setErrors] = useState(props.user && props.user.errors || {});

  const handleChange = param => val => {
    const allParams = {...params}
    setParams({ ...allParams, [param]: val });
    setErrors({ ...errors, [param]: null });
  };
  const formAttrs = {
    action: RailsRoutes.terms_user_path(),
    method: 'post',
    className: classes.tagForm
  }

  const terms_label = ( <span>
    I agree with <Link rel="stylesheet" href={theme.variables.tosUrl} target="_blank">Terms of Services</Link> and <Link rel="stylesheet" href={theme.variables.privacyUrl} target="_blank">Privacy Policy</Link>.
  </span> )

  return (
    <WebsaUITheme theme={props.theme}>
      <div className={classes.root}>
        <div className={classes.slider}>
          <SliderForm/>
        </div>
        <div className={classes.form}>
          <div className={classes.head}>
            <p>
              Sign in as a different user? <Link rel="stylesheet" data-method="DELETE" href={RailsRoutes.destroy_user_session_path()}>Sign out</Link>
            </p>
          </div>
          <RailsForm attrs={formAttrs}>
            <div>
              <div className={classes.tagForm}>
                <div className={classes.root}>
                  <div className={classes.loginForm}>
                    <h3 className="textStylesh2Default">Updated Terms of Services</h3>
                    <input type="hidden" name="user[terms_and_conditions]" value="" />
                    <input type="hidden" name="user[newsletter]" value="" />
                    <CheckboxLabel
                      checked={params.terms_and_conditions}
                      name="user[terms_and_conditions]"
                      label={terms_label}
                      value="1"
                      onChange={handleChange( "terms_and_conditions" )}/> {
                      errors.terms_and_conditions && ( <span className={classes.errorBlock}>
                        {errors.terms_and_conditions}
                      </span> )
                    }
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      className={classes.fullWidth}>
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </RailsForm>
          <Copyright/>
        </div>
      </div>
    </WebsaUITheme>
  );
}
