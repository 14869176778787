import React from "react";

const Logo = () => (
	<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 335.51 81.45">
		<defs>
			<style>
				.cls-1&#123;fill:url(#Gradient_bez_nazwy_8);&#125;
				.cls-2&#123;fill:url(#Gradient_bez_nazwy_8-2);&#125;
				.cls-3&#123;fill:#100237;&#125;
				.cls-4&#123;fill:url(#Gradient_bez_nazwy_8-4);&#125;
				.cls-5&#123;fill:url(#Gradient_bez_nazwy_8-5);&#125;
				.cls-6&#123;fill:url(#Gradient_bez_nazwy_8-6);&#125;
				.cls-7&#123;fill:#f9c358;opacity:0.5;&#125;
			</style>
			<linearGradient id="Gradient_bez_nazwy_8" x1="336.56" y1="22.68" x2="272.87" y2="22.68"
											gradientUnits="userSpaceOnUse">
				<stop offset="0" stopColor="#feba4c"/>
				<stop offset="0" stopColor="#feb94c"/>
				<stop offset="0.19" stopColor="#fb7f55"/>
				<stop offset="0.35" stopColor="#f8555b"/>
				<stop offset="0.48" stopColor="#f73b5f"/>
				<stop offset="0.56" stopColor="#f63160"/>
				<stop offset="0.71" stopColor="#f91c79"/>
				<stop offset="0.89" stopColor="#fb0892"/>
				<stop offset="1" stopColor="#fc009b"/>
			</linearGradient>
			<linearGradient id="Gradient_bez_nazwy_8-2" x1="336.56" y1="22.71" x2="272.87" y2="22.71"
											xlinkHref="#Gradient_bez_nazwy_8"/>
			<linearGradient id="Gradient_bez_nazwy_8-4" x1="69.55" y1="43.53" x2="19.36" y2="43.53"
											xlinkHref="#Gradient_bez_nazwy_8"/>
			<linearGradient id="Gradient_bez_nazwy_8-5" x1="266.8" y1="53.76" x2="37.65" y2="53.76"
											xlinkHref="#Gradient_bez_nazwy_8"/>
			<linearGradient id="Gradient_bez_nazwy_8-6" x1="75.86" y1="53.76" x2="16.91" y2="53.76"
											xlinkHref="#Gradient_bez_nazwy_8"/>
		</defs>
		<g id="Warstwa_2">
			<g id="Warstwa_1-2">
				<path className="cls-1"
							d="M291.51,13.9a8,8,0,0,1,8.17,6h-4.61a3.82,3.82,0,0,0-3.61-2.21c-2.58,0-4.35,1.92-4.35,5s1.77,5,4.35,5a3.82,3.82,0,0,0,3.61-2.21h4.61c-1,3.73-4.07,6-8.17,6a8.36,8.36,0,0,1-8.67-8.76A8.38,8.38,0,0,1,291.51,13.9Z"/>
				<path className="cls-2"
							d="M317.74,22.71c0,5.11-3.53,8.62-9,8.62h-6.45V14.1h6.45C314.21,14.1,317.74,17.58,317.74,22.71Zm-9.3,5c3.17,0,5-1.82,5-5s-1.86-5-5-5h-2v10Z"/>
				<path className="cls-2" d="M335.51,14.1V31.33h-4.2l-7-10.63V31.33h-4.2V14.1h4.2l7,10.67V14.1Z"/>
				<path className="cls-3"
							d="M89.7,64.51A2.54,2.54,0,0,1,87.17,62V6.51a2.63,2.63,0,0,1,.74-1.84,2.44,2.44,0,0,1,3.58,0,2.59,2.59,0,0,1,.75,1.84V62a2.56,2.56,0,0,1-2.54,2.54Z"/>
				<path className="cls-3"
							d="M126,62.15a19.78,19.78,0,0,1-4.41,1.75,19.46,19.46,0,0,1-12.4-.92A20,20,0,0,1,98.66,52.5a19.77,19.77,0,0,1,0-15.25A19.85,19.85,0,0,1,102.89,31a20.07,20.07,0,0,1,6.25-4.23,19.74,19.74,0,0,1,15.24,0,19.78,19.78,0,0,1,10.53,10.52,19.36,19.36,0,0,1,1.53,7.6v17a2.56,2.56,0,0,1-.75,1.83,2.48,2.48,0,0,1-1.88.79,2.41,2.41,0,0,1-1.79-.79,2.59,2.59,0,0,1-.74-1.83V58A19,19,0,0,1,126,62.15Zm-23.67-17.3a14.21,14.21,0,0,0,1.14,5.64,14.35,14.35,0,0,0,7.73,7.73,14.59,14.59,0,0,0,11.26,0,14.41,14.41,0,0,0,7.74-7.73A14.53,14.53,0,0,0,127,34.59a14.54,14.54,0,0,0-4.63-3.1,14.47,14.47,0,0,0-11.26,0,14.54,14.54,0,0,0-4.63,3.1,14.37,14.37,0,0,0-3.1,4.63A14.17,14.17,0,0,0,102.28,44.85Z"/>
				<path className="cls-3"
							d="M171.9,61.89a2.55,2.55,0,0,1-.74,1.87,2.43,2.43,0,0,1-1.79.75h-25.6a2.44,2.44,0,0,1-.48-.05,4.44,4.44,0,0,1-.48-.13.41.41,0,0,0-.26-.09v-.08a.5.5,0,0,1-.35-.18l-.17-.17a.35.35,0,0,1-.27-.35h-.08a.73.73,0,0,1-.14-.18.86.86,0,0,0-.13-.17.59.59,0,0,0,0-.26.39.39,0,0,1,0-.18v-.08a.7.7,0,0,1-.09-.44c-.06,0-.09-.09-.09-.26s0-.27.09-.27a1.18,1.18,0,0,1,.09-.52,1,1,0,0,0,.08-.22.78.78,0,0,1,.09-.22c.06,0,.09,0,.09-.08a.26.26,0,0,1,.09-.18l22.53-30H143.77a2.53,2.53,0,0,1-1.88-.74,2.45,2.45,0,0,1-.74-1.79,2.53,2.53,0,0,1,2.62-2.62h25.6a.38.38,0,0,1,.17,0,.41.41,0,0,0,.18.05,1.1,1.1,0,0,1,.43.08.78.78,0,0,0,.22.09.78.78,0,0,1,.22.09c.06.06.1.09.13.09s.07,0,.13.08h.09a.26.26,0,0,0,.09.18l.34.35v.08a1.57,1.57,0,0,1,.27.35l.17.35v.09a.41.41,0,0,0,.05.18.74.74,0,0,1,0,.26v.61a.26.26,0,0,0-.09.17.69.69,0,0,1-.09.27,1.4,1.4,0,0,1-.17.43c0,.06-.06.15-.18.26L148.84,59.35h20.53a2.54,2.54,0,0,1,2.53,2.54Z"/>
				<path className="cls-3"
							d="M180.2,21.79a2.54,2.54,0,0,1-2.54-2.53v-1a2.59,2.59,0,0,1,.75-1.84,2.37,2.37,0,0,1,1.79-.78,2.51,2.51,0,0,1,1.83.78,2.55,2.55,0,0,1,.79,1.84v1a2.38,2.38,0,0,1-.79,1.79A2.55,2.55,0,0,1,180.2,21.79Zm0,42.72a2.42,2.42,0,0,1-1.79-.75,2.52,2.52,0,0,1-.75-1.87V27.82a2.54,2.54,0,0,1,.75-1.88,2.45,2.45,0,0,1,1.79-.74,2.59,2.59,0,0,1,1.83.74,2.5,2.5,0,0,1,.79,1.88V61.89a2.48,2.48,0,0,1-.79,1.87A2.56,2.56,0,0,1,180.2,64.51Z"/>
				<path className="cls-3"
							d="M191.64,64.51a2.48,2.48,0,0,1-1.88-.79,2.59,2.59,0,0,1-.74-1.83v-17a19.18,19.18,0,0,1,1.53-7.6A19.83,19.83,0,0,1,201,26.73a19.74,19.74,0,0,1,15.24,0A19.78,19.78,0,0,1,226.8,37.25a19.18,19.18,0,0,1,1.53,7.6v17a2.56,2.56,0,0,1-.75,1.83,2.48,2.48,0,0,1-1.87.79,2.42,2.42,0,0,1-1.8-.79,2.59,2.59,0,0,1-.74-1.83v-17A14.33,14.33,0,0,0,222,39.22a14.35,14.35,0,0,0-7.73-7.73,14.62,14.62,0,0,0-11.32,0,14.77,14.77,0,0,0-4.58,3.1,14.37,14.37,0,0,0-3.1,4.63,14.17,14.17,0,0,0-1.14,5.63v17a2.59,2.59,0,0,1-.74,1.83A2.4,2.4,0,0,1,191.64,64.51Z"/>
				<path className="cls-3"
							d="M272.87,62.06a21.45,21.45,0,0,1-1.44,7.56,17.51,17.51,0,0,1-4,6.07,18.92,18.92,0,0,1-5.33,3.71,21.39,21.39,0,0,1-6.38,1.88c-.46.06-.91.1-1.35.13s-.89,0-1.35,0a19.09,19.09,0,0,1-5.86-.87,20.8,20.8,0,0,1-2.88-1.09A17,17,0,0,1,241.69,78a18.73,18.73,0,0,1-4.45-4.63,19.22,19.22,0,0,1-3-6.64,2.53,2.53,0,0,1,.26-2,2.36,2.36,0,0,1,1.57-1.18,1,1,0,0,0,.31,0,.87.87,0,0,1,.3,0,2.46,2.46,0,0,1,2.53,1.92,15,15,0,0,0,2.28,5.11,13.54,13.54,0,0,0,3.4,3.36A13.11,13.11,0,0,0,249,75.73a16.12,16.12,0,0,0,4.15.57h1a4.75,4.75,0,0,0,1-.09,16.67,16.67,0,0,0,4.63-1.4,13.58,13.58,0,0,0,6.95-7.16,14.64,14.64,0,0,0,1.09-5.85V58a18.83,18.83,0,0,1-14.5,6.47A19,19,0,0,1,245.58,63,20,20,0,0,1,235.1,52.5a19.77,19.77,0,0,1,0-15.25A19.85,19.85,0,0,1,239.33,31a20.07,20.07,0,0,1,6.25-4.23,19.74,19.74,0,0,1,15.24,0,19.78,19.78,0,0,1,10.53,10.52,19.35,19.35,0,0,1,1.52,7.6V62.06Zm-19.65-2.71a14.12,14.12,0,0,0,5.63-1.13,14.54,14.54,0,0,0,4.63-3.1,14.72,14.72,0,0,0,3.11-4.59,14.71,14.71,0,0,0,0-11.31,14.41,14.41,0,0,0-7.74-7.73,14.47,14.47,0,0,0-11.26,0,14.54,14.54,0,0,0-4.63,3.1,14.37,14.37,0,0,0-3.1,4.63,14.6,14.6,0,0,0,0,11.31,14.45,14.45,0,0,0,7.73,7.69A14.12,14.12,0,0,0,253.22,59.35Z"/>
				<path className="cls-3"
							d="M78.83,43.54A21.85,21.85,0,0,1,58.08,65.35h0a2.43,2.43,0,0,1-.61.05H4.84A4.36,4.36,0,0,1,.55,61.69H56.83a18.17,18.17,0,0,0,0-36.33H36.62V.66a5.49,5.49,0,0,1,3.71,5.15V21.68H57A21.89,21.89,0,0,1,78.83,43.54Z"/>
				<path className="cls-3"
							d="M4.84,65.87A4.84,4.84,0,0,1,.08,61.76L0,61.22H56.83a17.7,17.7,0,0,0,0-35.39H36.15V0l.63.22a5.94,5.94,0,0,1,4,5.59V21.2H57A22.4,22.4,0,0,1,79.31,43.54,22.28,22.28,0,0,1,58.1,65.82a2.94,2.94,0,0,1-.68.05Z"/>
				<path className="cls-3"
							d="M78.83,43.54A21.85,21.85,0,0,1,58.08,65.35h0a2.43,2.43,0,0,1-.61.05H4.84A4.36,4.36,0,0,1,.55,61.69H56.83a18.17,18.17,0,0,0,0-36.33H36.62V.66a5.49,5.49,0,0,1,3.71,5.15V21.68H57A21.89,21.89,0,0,1,78.83,43.54Z"/>
				<path className="cls-4"
							d="M21.06,39.88a1.71,1.71,0,0,1,0-3.41h8.73A1.72,1.72,0,0,0,29.74,33h-3.9a1.7,1.7,0,1,1,0-3.39H55.68a13.63,13.63,0,0,1,3.8.54,13.87,13.87,0,1,1-17.3,16.52h-12a1.71,1.71,0,1,1,0-3.42h10a1.7,1.7,0,0,0,0-3.4"/>
				<path className="cls-5" d="M38.09,52.06H24.23a1.71,1.71,0,1,0,0,3.41H38.09a1.71,1.71,0,1,0,0-3.41Z"/>
				<path className="cls-6" d="M15.76,52.06h-1a1.71,1.71,0,0,0,0,3.41h1a1.71,1.71,0,0,0,0-3.41Z"/>
				<ellipse className="cls-7" cx="61.25" cy="36.8" rx="1.76" ry="3"
								 transform="translate(-2.9 68.26) rotate(-57.12)"/>
				<ellipse className="cls-7" cx="62.95" cy="47.59" rx="3.34" ry="2.54"
								 transform="translate(-15.89 50.97) rotate(-39.54)"/>
				<ellipse className="cls-7" cx="55.8" cy="41.71" rx="1.7" ry="2.53"
								 transform="translate(-1.46 81.39) rotate(-71.5)"/>
			</g>
		</g>
	</svg>
);

export default Logo;
