import { useTheme, Checkbox, FormControlLabel } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import { tss } from 'tss-react';

/**
 * @function CheckboxLabel
 *
 * @param {checked,
  name,
  onChange,
  maxLength,
  indeterminate,
  disabled}
 *
 * @returns {jsx} React Conponent.
 *
 */
export default function CheckboxLabel({
  checked,
  name,
  label,
  onChange,
  indeterminate,
  disabled
}) {
  const theme = useTheme();
  const { classes } = tss.create(theme.customComponents.checkboxLabel.styles)();

  return (
    <FormControlLabel
      control={
        <Checkbox
          data-testid="checkbox-1234"
          checked={checked}
          onChange={e => onChange(e.target.checked)}
          classes={{
            root: classes.rootCheckbox,
            checked: classes.checked,
            disabled: classes.disabled
          }}
          value="1"
          name={name}
          disabled={disabled}
          indeterminate={indeterminate}
        />
      }
      classes={{
        root: classes.rootLabelContainer,
        label: classes.rootLabel
      }}
      label={label}
    />
  );
}
CheckboxLabel.propTypes = {
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool
};

CheckboxLabel.defaultProps = {
  indeterminate: false,
  disabled: false
};
