import OutlinedInput from "../Shared/OutlinedInput";
import React from "react";
import { useTheme } from "@mui/material";
import { tss } from 'tss-react';
import styles from "./styles";
import WebsaSelect from "../Shared/WebsaSelect";
import _ from "lodash";

import countryList from 'react-select-country-list';

export default function TabPersonal({params, handleChange, errors}) {
  const theme = useTheme();
  const { classes } = tss.create(styles(theme))();

  const accountName = params.name == null ? params.username : params.name;
  const countryOptions = countryList().getData();

  return (
    <div className={classes.personal}>
      <input type="hidden" name="account[legal_type]" value="personal" />

      <OutlinedInput
        customClass={classes.textField}
        name="account[name]"
        placeholder="Account name"
        value={accountName}
        onChange={handleChange("name")}
        error={errors.name}
        label="Account name"
        isUncontroled
        noAdditionalBtn
        />
      <WebsaSelect
        name="account[address_attributes][country_code]"
        width={450}
        menuHeight={196}
        options={countryOptions}
        placeholder="Country"
        label
        isSearchable
        value={_.find(countryOptions, { value: params.country_code })}
        onChange={handleChange("country_code")}
        />
      <span className={classes.signature}>Country choice will affect your products prices</span>
    </div>
  )
}
