import PropTypes from "prop-types";
import React from "react";
import { components } from "react-select";

const Option = props => {
  const {
    data: { label, second_label }
  } = props;

  return (
    <components.Option {...props}>
      <span>{label}</span>
      {second_label && (
        <span>
          <span className="separ" />
          <span className="textStylesDescription">{second_label}</span>
        </span>
      )}
    </components.Option>
  );
};

Option.propTypes = {
  innerProps: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Option;
