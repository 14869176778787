import PropTypes from "prop-types";
import React from "react";
import { components } from "react-select";

const Input = (isNumeric, { ...props }) => {
  const { isHidden, type, ...rest } = props;
  if (isHidden) {
    return <components.Input {...props} />;
  }
  return <components.Input {...rest} type={isNumeric ? "number" : type} />;
};

Input.propTypes = {
  isHidden: PropTypes.bool.isRequired,
  isNumeric: PropTypes.bool,
  type: PropTypes.string.isRequired
};
Input.defaultProps = {
  isNumeric: false
};

export default Input;
