import { tss } from 'tss-react';
import React, {useState} from 'react';
import styles from "./styles";
import Link from "@mui/material/Link";
import text from "./text";
import SliderForm from "../Shared/Slider/SliderForm";
import WebsaUITheme from '../Shared/WebsaUITheme';
import ThemeProvider from '../Shared/ThemeProvider';

import RailsForm from '../Shared/RailsForm';
import RailsRoutes from '../Shared/RailsRoutes';

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPersonal from "./TabPersonal";
import TabCompany from "./TabCompany";

import Button from "@mui/material/Button"
import Copyright from "../Shared/Copyright/Copyright";

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
    tabIndex: 0,
  }
}

export default function SignUp(props) {
  const theme = ThemeProvider(props.theme);
  const { classes, cx } = tss.create(styles(theme))();

  const [params, setParams] = useState(props.account);
  const [errors, setErrors] = useState(props.errors || {});

  const handleChange = param => val => {
    const allParams = {...params}
    setParams({ ...allParams, [param]: val });
    setErrors({ ...errors, [param]: null });
  };

  const formAttrs = {
    action: RailsRoutes.account_path(),
    method: 'post',
    className: classes.tagForm
  }

  const [tabIndex, setTabIndex] = useState(params.legal_type == 'company' ? 1 : 0);
  const [type, setType] = useState("button")

  const onTabChange = (event, newTabIndex) => {
    params.legal_type = newTabIndex == 0 ? 'personal' : 'company'
    setTabIndex(newTabIndex);
    const copyErrors = _.cloneDeep(errors);
    _.forEach(copyErrors, (value, key, obj) => {
      obj[key] = null
    });
    setErrors(copyErrors)
  };

  const tabList = [
    {
      label: "Personal",
      content:
      <TabPersonal
        params={params}
        handleChange={handleChange}
        errors={errors}/>,
      icon: "ico-user"
    },
    {
      label: "Company",
      content:
      <TabCompany
        params={params}
        handleChange={handleChange}
        errors={errors}/>,
      icon: "ico-company"
    }
  ]

  return (
    <WebsaUITheme theme={props.theme}>
      <div className={classes.root}>
        <div className={classes.slider}>
          <SliderForm/>
        </div>
        <div className={classes.form}>
          <div className={classes.head}>
            <p>
              Sign in as a different user? <Link rel="stylesheet" data-method="DELETE" href={RailsRoutes.destroy_user_session_path()}>Sign out</Link>
            </p>
          </div>
          <RailsForm attrs={formAttrs}>
            <div className={classes.tagForm}>
              <div className={classes.root}>
                <div className={cx(classes.accountForm, classes.customHeight)}>
                  <span className={classes.stepText}>Step 2 of 2</span>
                  <h3 className="textStylesh2Default">Create your account</h3>
                  <Tabs
                    value={tabIndex}
                    onChange={onTabChange}>
                    {tabList.map((item, ind) => {
                      return (
                        <Tab
                          className={cx(item.icon, classes.tab)}
                          disableRipple
                          label={item.label}
                          classes={{
                            selected: classes.tabSelected
                          }}
                          {...a11yProps(ind)}
                          key={item.label} />
                      );
                    })}
                  </Tabs>
                  {tabList[tabIndex] && (<div>{tabList[tabIndex].content}</div>)}
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    className={classes.fullWidth}>
                    Create account
                  </Button>
                </div>
              </div>
            </div>
          </RailsForm>
          <Copyright/>
        </div>
      </div>
    </WebsaUITheme>
  );
}
