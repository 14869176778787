import React from "react";

class RailsForm extends React.Component {
  formAttributes() {
    if (!this.isCustomMethod()) {
      return this.props.attrs;
    }

    return { ...this.props.attrs, method: 'POST' }
  }

  formMethod() {
    return this.props.attrs.method.toUpperCase()
  }

  isCustomMethod() {
    return !['GET', 'POST'].includes(this.formMethod())
  }

  render () {
    return (
      <form {...this.formAttributes()}>
        <input type='hidden' name='authenticity_token' value={gon.authenticity_token}/>
        {this.isCustomMethod() && (<input type='hidden' name='_method' value={this.formMethod()} />)}

        {this.props.children}
      </form>
    );
  }
}

export default RailsForm
