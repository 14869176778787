import React from "react";

const Google = () => (
	<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
			 xmlnsXlink="http://www.w3.org/1999/xlink">
		<g id="Google">
			<path
				d="M23.52,12.2727273 C23.52,11.4218182 23.4436364,10.6036364 23.3018182,9.81818182 L12,9.81818182 L12,14.46 L18.4581818,14.46 C18.18,15.96 17.3345455,17.2309091 16.0636364,18.0818182 L16.0636364,21.0927273 L19.9418182,21.0927273 C22.2109091,19.0036364 23.52,15.9272727 23.52,12.2727273 L23.52,12.2727273 Z"
				fill="#4285F4"></path>
			<path
				d="M12,24 C15.24,24 17.9563636,22.9254545 19.9418182,21.0927273 L16.0636364,18.0818182 C14.9890909,18.8018182 13.6145455,19.2272727 12,19.2272727 C8.87454545,19.2272727 6.22909091,17.1163636 5.28545455,14.28 L1.27636364,14.28 L1.27636364,17.3890909 C3.25090909,21.3109091 7.30909091,24 12,24 L12,24 Z"
				fill="#34A853"></path>
			<path
				d="M5.28545455,14.28 C5.04545455,13.56 4.90909091,12.7909091 4.90909091,12 C4.90909091,11.2090909 5.04545455,10.44 5.28545455,9.72 L5.28545455,6.61090909 L1.27636364,6.61090909 C0.463636364,8.23090909 0,10.0636364 0,12 C0,13.9363636 0.463636364,15.7690909 1.27636364,17.3890909 L5.28545455,14.28 L5.28545455,14.28 Z"
				fill="#FBBC05"></path>
			<path
				d="M12,4.77272727 C13.7618182,4.77272727 15.3436364,5.37818182 16.5872727,6.56727273 L20.0290909,3.12545455 C17.9509091,1.18909091 15.2345455,0 12,0 C7.30909091,0 3.25090909,2.68909091 1.27636364,6.61090909 L5.28545455,9.72 C6.22909091,6.88363636 8.87454545,4.77272727 12,4.77272727 L12,4.77272727 Z"
				fill="#EA4335"></path>
		</g>
	</svg>
);

export default Google;
