import React from "react";
import { useTheme } from "@mui/material";

import { tss } from 'tss-react';

export default function Slide({icon, label, title, content}) {
  const theme = useTheme();
  const { classes, cx } = tss.create(theme.customComponents.slider.styles)();
  return (
    <div className={classes.sliderRoot}>
      <div className={classes.slideContainer}>
        <span className={cx(icon, classes.customIcon)}/>
        <span className="textStyle6Active">{label}</span>
        <span className={cx("textStyleh1Active", classes.title)}>{title}</span>
        <p className="textStylesDefault54">{content}</p>
      </div>
    </div>
  )
}
