import { tss } from 'tss-react';
import Button from "@mui/material/Button";
import React, { useState, useEffect } from "react";

import styles from "./styles";
import _ from "lodash";

import Link from "@mui/material/Link";
import SliderForm from "../Shared/Slider/SliderForm";
import WebsaUITheme from '../Shared/WebsaUITheme';
import ThemeProvider from '../Shared/ThemeProvider';

import RailsForm from '../Shared/RailsForm';
import RailsRoutes from '../Shared/RailsRoutes';
import Copyright from "../Shared/Copyright/Copyright";

import AuthCode from "react-auth-code-input";

export default function TwoFA(props) {
  const theme = ThemeProvider(props.theme);
  const { classes, cx } = tss.create(styles(theme))();

  const [twoFA, setTwoFA] = useState();
  const [error, setError] = useState(props.flash && props.flash.alert);

  const formAttrs = {
    action: RailsRoutes.user_two_factor_authentication_path(),
    autoComplete: 'off',
    method: 'PUT',
    className: classes.tagForm
  }

  useEffect(() => {
    if (twoFA) setError(null);
  }, [twoFA]);

  return (
    <WebsaUITheme theme={props.theme}>
      <div className={classes.root}>
        <div className={classes.slider}>
          <SliderForm/>
        </div>
        <div className={classes.form}>
          <div className={classes.head}>
            <p>
              Sign in as a different user? <Link rel="stylesheet" data-method="DELETE" href={RailsRoutes.destroy_user_session_path()}>Sign out</Link>
            </p>
          </div>
          <RailsForm attrs={formAttrs}>
            <div className={classes.root}>
              <div className={classes.loginForm}>
                <h3 className="textStylesh2Default">
                  Two-factor authentication
                  <p className="textStylesInfoBlockDescription">
                    Please fill in a six-digit code from your 2FA application:
                  </p>
                </h3>

                <AuthCode
                  allowedCharacters="numeric"
                  containerClassName={classes.otpContainer}
                  inputClassName={cx(classes.otpInput, error && classes.otpError)}
                  onChange={setTwoFA}
                />
                <p className={classes.error}>
                  {error}&nbsp;
                </p>
                <input name="code" type="hidden" value={twoFA}/>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={e => e.currentTarget.form.submit()}
                  color="primary"
                  className={classes.width324}
                  disabled={_.size(twoFA) !== 6}>
                  Verify code
                </Button>
              </div>
            </div>
          </RailsForm>
          <Copyright/>
        </div>
      </div>
    </WebsaUITheme>
  );
}
