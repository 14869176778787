import React from "react";
import { useTheme } from "@mui/material";

import { tss } from 'tss-react';

export default function Copyright() {
  const theme = useTheme();
  const { classes } = tss.create(theme.customComponents.copyright.styles)();

  const currentYear = () => {
    let dt = new Date();
    return dt.getFullYear();
  }

  return (
    <p className={classes.footer}>
      {`© ${currentYear()} ${theme.customComponents.copyright.host}`}
    </p>
  );
}
