import colors from "../colors";

export default {
  content: [
    {
      icon: "ico-anycast_cdn",
      label: "Fast & Affordable CDN",
      title: "For companies with substantial traffic demands, BlazingCDN stands as the ultimate choice",
      content: "Fast upload increases the site's position in Google search. Supports SSL/HTTPS. Anycast technology has made the service even faster. Free trial period."
    }
  ],
  styles: {
    root: {
      "@media (min-width:1024px) and (orientation:landscape)": {
        "& .carousel .control-dots .dot": {
          width: 12,
          height: 12,
          boxShadow: "none",
          backgroundColor: colors.pink18,
          opacity: 1,
          "&.selected": {
            backgroundColor: colors.pink,
          }
        }
      },
      "& .slide": {
        backgroundColor: "transparent"
      }
    },
    logo: {
      position: "absolute",
      left: 27,
      top: 17,
      zIndex: 10,
      width: 93,
      height: 28
    },
    sliderRoot: {
      backgroundColor: colors.black2,
      height: "100vh",
    },
    slideContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: 442,
      paddingLeft: 9,
      paddingRight: 9,
      width: "100%",
      margin: "auto",
      height: "100%",
      justifyContent: "center",
    },
    customIcon: {
      width: 128,
      height: 128,
      minWidth: 128,
      lineHeight: `128px`,
      display: "inline-flex",
      verticalAlign: "middle",
      marginBottom: 33,
      "&::before": {
        fontSize: 128,
        width: 128,
        height: 128,
        lineHeight: "128px",
        color: colors.pink
      }
    },
    title: {
      marginTop: 28,
      marginBottom: 45,
      "& + *": {
        margin: 0,
      }
    }
  }
};
