import PropTypes from "prop-types";
import React from "react";

const ClearIndicator = props => {
  const {
    innerProps: { ref, ...restInnerProps }
  } = props;
  return (
    <div {...restInnerProps} ref={ref}>
      <span className="ico-error_status" />
    </div>
  );
};

ClearIndicator.propTypes = {
  innerProps: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ClearIndicator;
