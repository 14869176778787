import { tss } from 'tss-react';
import React from 'react';
import styles from "./styles";
import Link from "@mui/material/Link";
import SliderForm from "../Shared/Slider/SliderForm";

import RailsRoutes from "../Shared/RailsRoutes";
import WebsaUITheme from "../Shared/WebsaUITheme";
import ThemeProvider from '../Shared/ThemeProvider';
import Copyright from "../Shared/Copyright/Copyright";

export default function Error(props) {
  const theme = ThemeProvider(props.theme);
  const { classes } = tss.create(styles(theme))();

  return (
    <WebsaUITheme theme={props.theme}>
      <div className={classes.root}>
        <div className={classes.slider}>
          <SliderForm/>
        </div>
        <div className={classes.form}>
          <div className={classes.head}>
            <p>
              Return to <Link rel="stylesheet" href={RailsRoutes.new_user_session_path()}>Sign in</Link>
            </p>
          </div>
          <div className={classes.flexColumn}>
            <span className="ico-warning_info"/>
            <span className="textStylesh2Default">
              {props.flash.alert}
            </span>
            <span className="textStylesInfoBlockDescription">
              {props.flash.alert2}
            </span>
          </div>
          <Copyright/>
        </div>
      </div>
    </WebsaUITheme>
  );
}
