import React from 'react';
import styles from "./styles";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { tss } from 'tss-react';

import SliderForm from "../Shared/Slider/SliderForm";
import WebsaUITheme from '../Shared/WebsaUITheme';
import ThemeProvider from '../Shared/ThemeProvider';

import RailsForm from '../Shared/RailsForm';
import RailsRoutes from '../Shared/RailsRoutes';
import Copyright from "../Shared/Copyright/Copyright";

export default function AfterForgot(props) {
  const theme = ThemeProvider(props.theme);
  const { classes } = tss.create(styles(theme))();

  const formAttrs = {
    action: RailsRoutes.user_password_path(),
    method: 'post',
    className: classes.tagForm
  }

  return (
    <WebsaUITheme theme={props.theme}>
      <div className={classes.root}>
        <div className={classes.slider}>
          <SliderForm/>
        </div>
        <div className={classes.form}>
          <div className={classes.head}>
            <p>
              Return to <Link rel="stylesheet" href={RailsRoutes.new_user_session_path()}>Sign in</Link>
            </p>
          </div>
          <RailsForm attrs={formAttrs}>
            <input type='hidden' name='user[email]' value={props.email} />
            <div className={classes.flexColumn}>
              <span className="ico-email_warning"/>
              <span className="textStylesh2Default">
                Email has been sent
              </span>
              <span className="textStylesInfoBlockDescription">
                If account exists, an email will be sent with further instructions.
              </span>
              <Button variant="text" type="submit" color="primary" onClick={() => {}}>
                <span className="ico-reset"/>
                Resend email
              </Button>
              <p className="textStyles12RegularDefaultLetter">
                Problems verifying account?
                <br/>
                Contact <Link rel="stylesheet" href={`mailto:${theme.variables.supportEmail}`}>{theme.variables.supportEmail}</Link>
              </p>
            </div>
          </RailsForm>
          <Copyright/>
        </div>
      </div>
    </WebsaUITheme>
  );
}
