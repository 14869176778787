import { tss } from 'tss-react';
import Button from "@mui/material/Button";
import React from "react";

import styles from "./styles";
import OutlinedInput from "../Shared/OutlinedInput";
import Link from "@mui/material/Link";
import SliderForm from "../Shared/Slider/SliderForm";
import WebsaUITheme from '../Shared/WebsaUITheme';
import ThemeProvider from '../Shared/ThemeProvider';

import RailsForm from '../Shared/RailsForm';
import RailsRoutes from '../Shared/RailsRoutes';
import Copyright from "../Shared/Copyright/Copyright";

export default function NewPassword(props) {
  const theme = ThemeProvider(props.theme);
  const { classes } = tss.create(styles(theme))();

  const error = props.flash.error;

  const formAttrs = {
    action: RailsRoutes.user_password_path(),
    method: 'put',
    className: classes.tagForm
  }

  return (
    <WebsaUITheme theme={props.theme}>
      <div className={classes.root}>
        <div className={classes.slider}>
          <SliderForm/>
        </div>
        <div className={classes.form}>
          <div className={classes.head}>
            <p>
              Return to <Link rel="stylesheet" href={RailsRoutes.new_user_session_path()}>Sign in</Link>
            </p>
          </div>
          <RailsForm attrs={formAttrs}>
            <input type="hidden" name="user[reset_password_token]" value={props.user.reset_password_token} />
            <div className={classes.root}>
              <div className={classes.loginForm}>
                <h3 className="textStylesh2Default">Enter your new password</h3>
                {error && (
                  <span className={classes.errorBlockBig}>
                    {error}
                  </span>
                )}
                <OutlinedInput
                  customClass={classes.textField}
                  name="user[password]"
                  placeholder="New password"
                  label="New password"
                  type="password"
                  isUncontroled
                  noAdditionalBtn
                  />
                <OutlinedInput
                  customClass={classes.textField}
                  name="user[password_confirmation]"
                  placeholder="Confirm new password"
                  label="Confirm new password"
                  type="password"
                  isUncontroled
                  noAdditionalBtn
                  />
                <Button variant="contained" type="submit" color="primary" className={classes.fullWidth}>
                  Change password
                </Button>
              </div>
            </div>
          </RailsForm>
          <Copyright/>
        </div>
      </div>
    </WebsaUITheme>
  );
}
