import colors from "../colors";

export default {
  styles: {
    rootCheckbox: {
      marginRight: 9,
      padding: 0,
      color: "#a3a3a3",
      "&$checked": {
        color: colors.pink,
        "& + *": {
          color: colors.pink
        }
      }
    },
    checked: {},
    rootLabelContainer: {
      margin: "0 0 9px 0",
      width: "100%",
      display: "inline-flex",
      alignItems: "end"
    },
    rootLabel: {
      fontSize: 16,
      fontWeight: 500,
      color: "rgba(0, 0, 0, 0.541)",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    }
  }
};
