import colors from "../colors";

export default {
  styles: {
    dividerText: {
      width: "100%",
      textAlign: "center",
      margin: 0,
      height: 16,
      "& hr": {
        height: 1,
        backgroundColor: colors.black9,
        border: "none",
        outline: "none"
      },
      "& .textStylesDescription": {
        backgroundColor: colors.white,
        position: "relative",
        top: -19,
        padding: "0 18px"
      }
    },
    flex: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      marginTop: 20,
      "& > a, button": {
        width: "100%",
        "& svg": {
          width: 18,
          height: 18,
          margin: "0 9px 0 -7px"
        }
      }
    },
    hr_style: {
      height: 1,
      backgroundColor: colors.black9,
      border: "none",
      outline: "none",
      margin: "8px 0",
      display: "block"
    }
  }
};
