import { IconButton } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { components } from "react-select";

const DropdownIndicator = props => {
  const {
    isDisabled,
    selectProps: { menuIsOpen }
  } = props;
  return components.DropdownIndicator &&
  !isDisabled && (
    <components.DropdownIndicator {...props}>
      <IconButton aria-haspopup="true" data-testid="select_icon-button" size="large">
        <span className="ico-arrow" style={{ transform: menuIsOpen && "rotate(180deg)" }} />
      </IconButton>
    </components.DropdownIndicator>
  );
};

DropdownIndicator.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  selectProps: PropTypes.objectOf(PropTypes.any).isRequired
};

export default DropdownIndicator;
