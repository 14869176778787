import { useTheme, Button } from "@mui/material";
import React from "react";

import Google from "./Google";

import RailsRoutes from '../RailsRoutes';

import { tss } from 'tss-react';

export default function DividerSocialBtn({text}) {
    const theme = useTheme();
    const { classes } = tss.create(theme.customComponents.dividerSocialBtn.styles)();

    if (!theme.configuration.socialAuthEnabled) {
      return false;
    }

    return (
      <>
        <p className={classes.dividerText}>
          <span className={classes.hr_style}/>
          <span className="textStylesDescription">{text}</span>
        </p>
        <div className={classes.flex}>
          <Button variant="outlined" color="primary" href={RailsRoutes.user_google_oauth2_omniauth_authorize_path()}
                  data-method="POST">
            <Google/>
            Google
          </Button>
        </div>
      </>
    );
}
