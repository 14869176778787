import React from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useTheme } from "@mui/material";
import Slide from "./Slide";

import { tss } from 'tss-react';

export default function SliderForm() {
  const theme = useTheme();
  const { classes } = tss.create(theme.customComponents.slider.styles)();

  let logoBlock;
  if (theme.configuration.gloryToUkraine) {
    logoBlock = (
      <>
        <a href="https://websa.advancedhosting.com/StandWithUkraine.html" className={classes.logo}>
          <theme.images.Logo/>
        </a>
        <a href="https://websa.advancedhosting.com/StandWithUkraine.html" className={classes.gloryToUkraine}>
          <theme.images.GloryToUkraine/>
        </a>
      </>
    );
  } else {
    logoBlock = (
      <a href="/" className={classes.logo}>
        <theme.images.Logo/>
      </a>
    );
  }

  return (
    <div className={classes.root}>
      {logoBlock}
      <Carousel autoPlay showArrows={false} showThumbs={false} showStatus={false} infiniteLoop={true} interval={10000}>
        {theme.customComponents.slider.content.map((each) => <Slide {...each} key={each.label} />)}
      </Carousel>
    </div>
  )
}
