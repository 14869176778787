import ThemeWebsa from "./websa/theme";
import ThemeBlazing from "./blazing/theme";

export default function(themeName) {
  switch (themeName) {
    case 'blazing':
      return ThemeBlazing;
    default:
      return ThemeWebsa;
  }
}
