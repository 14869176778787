import { useTheme, IconButton, InputAdornment, TextField } from "@mui/material";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { tss } from "tss-react";

/**
 * @param {*} props defaultValue && onInputComplete || value && onChange
 *
 * Recommended usage:
 *   Use props "defaultValue" && "onInputComplete" to avoid calls on each change.
 *
 * Optional usage:
 *   Use props "value" && "onChange" to get change on each symbol (as for validation).
 */
export default function OutlinedInput(props) {
  const {
    autoComplete,
    autoFocus,
    backgroundColor,
    disabled,
    clear,
    error,
    defaultValue: initValue,
    inputRef,
    isLeadingErrorIcon,
    isLeadingWarningIcon,
    label,
    leadingIconName,
    maxLength,
    minWidth,
    name,
    onChange,
    onInputComplete,
    placeholder,
    resetDefaultValueOnChange,
    size,
    type,
    value,
    warning,
    width,
    multiline,
    rows,
    isUncontroled,
    customClass,
  } = props;

  const theme = useTheme();
  const { classes, cx } = tss.create(theme.customComponents.outlinedInput.styles)();
  const thisRef = useRef();
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [defaultValue, setDefaultValue] = useState(initValue);
  const [inputValue, setInputValue] = useState(initValue);
  const [isInputFocused, setInputFocused] = useState(autoFocus);
  const [pass, setPass] = useState(true)

  const getRef = ref => {
    thisRef.current = ref;
    if (typeof inputRef === "function") inputRef(ref);
  };

  const resetToInitValue = () => {
    thisRef.current.value = initValue;
    setInputValue(initValue);
    setIsValueChanged(false);
    clear();
  };

  useEffect(() => {
    if (resetDefaultValueOnChange) resetToInitValue();
  }, [initValue]);

  useEffect(() => {
    if (!value && !!onChange) resetToInitValue();
  }, [value]);

  const focusInput = () => {
    thisRef.current.focus();
  };

  const setDefault = e => {
    const text = _.get(e, "target.value", undefined);
    setDefaultValue(text);
    setInputValue(text);
    setIsValueChanged(false);
  };

  const onInputChange = e => {
    const text = _.get(e, "target.value", undefined);
    setIsValueChanged(text !== defaultValue);
    setInputValue(text);
    if (typeof onChange === "function") onChange(text);
  };

  const onKeyDown = e => {
    if (e.key === "Escape") {
      e.preventDefault();
      e.stopPropagation();
      setIsValueChanged(false);
      thisRef.current.value = defaultValue;
      thisRef.current.blur();
    }
  };

  const completeInput = e => {
    const text = _.get(e, "target.value", undefined);
    setInputValue(text);
    setDefaultValue(text);
    setIsValueChanged(false);
    onInputComplete(text, resetToInitValue);
  };

  const getHelper = () => {
    if (typeof error === "string") return <span>{error}</span>;
    if (typeof warning === "string") return <span>{warning}</span>;
    return null;
  };

  const startAdornment = () => {
    let res;
    /** Icon ico-warning_info is used for both error and warning leading icon if any */
    if (error && isLeadingErrorIcon) res = "ico-warning_info error";
    else if (warning && isLeadingWarningIcon) res = "ico-warning_info warning";
    else if (leadingIconName) res = leadingIconName;
    return res ? (
      <InputAdornment position="start" className="outlinedInput">
        <span className={cx(res)} />
      </InputAdornment>
    ) : null;
  };

  const additionalProps = {};
  if(!isUncontroled){
    additionalProps.value= value ;
  }

  const handleClickShowPassword = (e) => {
    setPass(!pass);
  };

  const handleMouseDownPassword = (event) => {
      event.preventDefault();
  };

  return (
    <TextField
      style={{ width, minWidth, backgroundColor }}
      fullWidth
      disabled={disabled}
      error={!!error}
      //  TextField accepts two sizes only - "medium" and "small",
      //  so "large" size in this module corresponds "medium" size in TextField
      size={size === "large" ? "medium" : size}
      helperText={getHelper()}
      type={type === "password" && pass ? "password" : "text"}
      className={cx(classes.textFieldRoot, customClass, {
        hastext: size === "small" && inputValue !== "",
        warning,
        sizeLarge: size === "large"
      })}
      defaultValue={value}
      label={label}
      placeholder={placeholder}
      name={name}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      inputRef={getRef}
      multiline={multiline}
      rows={rows}
      onChange={onInputChange}
      onKeyDown={onKeyDown}
      onFocus={e => {
        setInputFocused(true);
        setDefault(e);
      }}
      onBlur={e => {
        setInputFocused(false);
        completeInput(e);
      }}
      // https://material-ui.com/components/text-fields/#shrink
      InputLabelProps={
        size === "small" ? { shrink: isInputFocused || inputValue !== "" } : undefined
      }
      // For only HTML tag Input
      inputProps={{ "data-testid": "outlinedTestIdInput", maxLength: maxLength }}
      // For only HTML tag Input
      /* eslint-disable-next-line react/jsx-no-duplicate-props */
      InputProps={{
        maxLength,
        startAdornment: startAdornment(),
        endAdornment: type === "password" && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={e => handleClickShowPassword(e)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                tabIndex={-1}
                size="large">
                <span className={pass ? "ico-visibility" : "ico-visibility_off"}/>
              </IconButton>
            </InputAdornment>
        )
      }}
      { ...additionalProps }
    />
  );
}

OutlinedInput.propTypes = {
  /** off or new-password if we want to override browser autocomplete */
  autoComplete: PropTypes.string,
  /** True, if input has to be focused after first render */
  autoFocus: PropTypes.bool,
  /** Input backgroundColor */
  backgroundColor: PropTypes.string,
  /** Calls with resetToInitValue func in "small" mode only */
  clear: PropTypes.func,
  /** If disabled */
  disabled: PropTypes.bool,
  /** Error status or message */
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** Input value for first render */
  defaultValue: PropTypes.string,
  /** Function with input ref passed as argument */
  inputRef: PropTypes.func,
  /** True, if input should show a left error icon: ico-warning_info */
  isLeadingErrorIcon: PropTypes.bool,
  /** True, if input should show a left warning icon: ico-warning_info */
  isLeadingWarningIcon: PropTypes.bool,
  /** Label text */
  label: PropTypes.string,
  /** Left icon name in the Input i.e. "ico-private_cluster" */
  leadingIconName: PropTypes.string,
  /** Max length of string for input */
  maxLength: PropTypes.number,
  /** Input container minWidth */
  minWidth: PropTypes.number,
  /** Input name as form field */
  name: PropTypes.string,
  /** Function with input value passed as argument which is calling on each change. */
  onChange: PropTypes.func,
  /** Function with input value passed as argument which is calling on input blur. */
  onInputComplete: PropTypes.func,
  /** Input placeholder */
  placeholder: PropTypes.string,
  /** If true - default value will be reset to new on defaultValue change */
  resetDefaultValueOnChange: PropTypes.bool,
  /** Input size small -> height 36px, other - 54px. Size changes input functionality! */
  size: PropTypes.oneOf(["small", "medium", "large"]),
  /** Valid HTML5 input type */
  type: PropTypes.string,
  /** Direct input value */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Warning status or message */
  warning: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** Input container width */
  width: PropTypes.number,
  /** multiline */
  multiline: PropTypes.bool,
  /** rows for multiline */
  rows: PropTypes.number,
  noAdditionalBtn: PropTypes.bool,
  customClass: PropTypes.string
};
OutlinedInput.defaultProps = {
  multiline: false,
  rows: 1,
  autoComplete: undefined,
  autoFocus: false,
  backgroundColor: "transparent",
  disabled: false,
  clear: _.noop,
  error: false,
  defaultValue: "",
  inputRef: _.noop,
  isLeadingErrorIcon: false,
  isLeadingWarningIcon: false,
  label: undefined,
  leadingIconName: undefined,
  maxLength: 255,
  minWidth: undefined,
  name: undefined,
  onChange: undefined,
  onInputComplete: _.noop,
  placeholder: undefined,
  resetDefaultValueOnChange: false,
  size: "medium",
  type: "text",
  value: undefined,
  warning: false,
  width: undefined,
  noAdditionalBtn: false,
  customClass: null
};

// TODO better + tests
// Что пострадало:
// https://gitlab.appsberry.com/mootha/websa-ui/merge_requests/1476

// src/components/Views/create_react/step3/NamesAndNumber/TableServers/table.js,
// страница http://localhost:3000/#/create_new_server, > step2 Use private network:Off > step3 Define names and number of servers:
// результат - не отображается имя сервера по умолчанию.

// src/components/Views/pnetworks/create/components/NetworkName/name.js http://localhost:3000/#/create_new_pnet Enter network name:
// результат - не отображается имя сети по умолчанию, значение не вводится.

// src/components/Views/pnetworks/item/components/header/header.js http://localhost:3000/#/private_networks/73ef8df8-a9ba-43dd-bbbd-194b6fac86bf
// результат - в header не отображается имя сети по умолчанию, значение не вводится.

// src/components/Views/server_item/Views/ServerDetail/header/header.js
// http://localhost:3000/#/servers/28dc5d40-e49c-4632-8726-0fb094159da7
// результат - в header не отображается имя сервера по умолчанию, значение не вводится.

// src/components/Views/volumes/components/VolumeName/VolumeName.js
// http://localhost:3000/#/volumes/new > Enter a volume name:
// результат - в инпуте не отображается имя вольюма по умолчанию, значение не вводится.

// Использование инпута без defaultValue не проверялось.
