import colors from "../colors";

export default  {
  styles: {
    button: {
      zIndex: 999,
      display: "inline-flex",
      visibility: "hidden"
    },
    textFieldRoot: {
      "&.hastext input, &.hastext label": {
        color: colors.pink
      },
      "&.hastext .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.red54,
        borderWidth: 2
      },
      "&.hastext .button-reset-default": {
        visibility: "visible"
      },
      "& .MuiOutlinedInput-input": {
        paddingLeft: 0
      }
    }
  }
};
