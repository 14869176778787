import colors from "../colors";

export default {
  content: [
    {
      icon: "ico-websa",
      label: "",
      title: "Dear customers",
      content: "Please note, free trials for Websa services are temporarily suspended. Thank you for understanding."
    },
    {
      icon: "ico-cloud_servers",
      label: "Cloud Servers",
      title: "Fast and scalable solutions. Fully controlled",
      content: "99.99% fault tolerance. Fast NVMe SSD. Hourly billing. Transparent pricing."
    },
    {
      icon: "ico-anycast_cdn",
      label: "Anycast CDN",
      title: "Use the best way to speed up the content downloading",
      content: "Fast upload increases the site's position in Google search. Supports SSL/HTTPS. Anycast technology has made the service even faster."
    },
    {
      icon: "ico-video-cdn",
      label: "Video CDN",
      title: "The best solution for streaming video",
      content: "Speed and the best protection on the market from hotlink. Flexible settings for video. Anycast technology."
    },
    {
      icon: "ico-private_cluster",
      label: "Private Cloud",
      title: "Order nodes specifically tailored to your business needs",
      content: "Unlimited virtualisation. Dedicated hardware. Custom configuration. Get guaranteed and isolated resources."
    },
    {
      icon: "ico-cloud",
      label: "Cloud Storage",
      title: "Reliable cloud storage of your data",
      content: "Triple replication between independent data-centers. Access via HTTP(s), FTP, SFTP, RCLONE, OpenStack Object Storage API or S3."
    },
    {
      icon: "ico-anycast",
      label: "Anycast DNS",
      title: "It increases the effectiveness of your resource",
      content: "Reduced response time between client and server. Enhanced protection of your data. High level of reliability."
    },
    {
      icon: "ico-dedicated-servers",
      label: "Dedicated Servers",
      title: "Hosting that grows with your business",
      content: "TIER 4 data centers in Netherlands, US and Hong Kong. Individual configuration on demand. 24/7 support by appointed administrators. ECC (Error Checking Correction) technology is used in RAM."
    }
  ],
  styles: {
    root: {
      "@media (min-width:1024px) and (orientation:landscape)": {
        "& .carousel .control-dots .dot": {
          width: 12,
          height: 12,
          boxShadow: "none",
          backgroundColor: colors.blue18,
          opacity: 1,
          "&.selected": {
            backgroundColor: colors.blue90,
          }
        }
      },
      "& .slide": {
        backgroundColor: "transparent"
      }
    },
    logo: {
      position: "absolute",
      left: 27,
      top: 17,
      zIndex: 10
    },
    gloryToUkraine: {
      position: "absolute",
      right: 0,
      top: 0,
      zIndex: 10
    },
    sliderRoot: {
      backgroundColor: colors.blue9,
      height: "100vh",
    },
    slideContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: 442,
      paddingLeft: 9,
      paddingRight: 9,
      width: "100%",
      margin: "auto",
      height: "100%",
      justifyContent: "center",
    },
    customIcon: {
      width: 128,
      height: 128,
      minWidth: 128,
      lineHeight: `128px`,
      display: "inline-flex",
      verticalAlign: "middle",
      marginBottom: 33,
      "&::before": {
        fontSize: 128,
        width: 128,
        height: 128,
        lineHeight: "128px",
        color: colors.blue90
      }
    },
    title: {
      marginTop: 28,
      marginBottom: 45,
      "& + *": {
        margin: 0,
      }
    }
  }
};
