import PropTypes from "prop-types";
import React from "react";
import { components } from "react-select";

import svg from "./svg-icons.svg";

const GroupHeading = props => {
  const { children } = props;
  return (
    <div className="group_flex">
      <svg viewBox="0 0 24 24">
        <use xlinkHref={`${svg}#${children.toLowerCase()}`} />
      </svg>
      <components.GroupHeading {...props} />
    </div>
  );
};

GroupHeading.propTypes = {
  children: PropTypes.string.isRequired
};

export default GroupHeading;
